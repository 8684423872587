<template>
   <div class="card cursor-pointer mb-0" ref="question" @click="selectQuestion">
      <div class="body text-center">
         <h6 class="limitador mt-0 mb-3">{{ question.description == null ? 'Unknown' : question.description }}</h6>
         <button type="button" class="btn btnLogins-edit btn-sm mr-1 rounded" @click="edit">
            <span><i class="far fa-pencil font-13"></i><span class="hidden-md">{{$t('serverSettings.buttons.editBtn')}}</span></span>
         </button>
         <button type="button" class="btn btnLogins-danger btn-sm rounded" @click="remove(question.id)">
            <span><i class="far fa-trash font-13"></i><span class="hidden-md">{{$t('serverSettings.buttons.deleteBtn')}}</span></span>
         </button>
      </div>
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
   name: 'Question',
   props: ['question'],
   computed: {
		...mapState({
			urlRest: state => state.urlRest
		}),
	},
   methods: {
      selectQuestion : function () {
         this.$emit('selectQuestion', this.question)
      },
      edit : function () {
         this.$emit('edit', this.question)
      },
      remove : function (idQuestion) {
         Swal.fire({
            icon: 'warning',
            title: this.$t("serverSettings.swal.question.delQuestion"),
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel")

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)

               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicos/deletequestion',
                  params:{
                     id: idQuestion
                  }
               }).then(() => {
                  this.$refs.question.style.display = 'none'

                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t("serverSettings.swal.question.delSucess"),
                  })
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t("serverSettings.swal.expired"),
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Error: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     })
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               })
            }
         });
      }
   }
}

</script>