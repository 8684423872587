<template>
   <div class="row">
      <div class="col-12">
         <div class="card mb-2">
            <div class="card-body text-right p-y-10">
               <button class="btn btn-default" @click="add"><i class="far fa-plus font-13 mr-1"></i> {{ $t("whitelistQuestions.button.add") }}</button>
            </div>
         </div>
      </div>
      <div class="col-12">
         <div class="cards-2">
            <question v-for="(question, index) in channel.questions" :key="index" :question="question" @selectQuestion="selectQuestion($event)" @edit="edit($event)" />
         </div>
         <div class="w-100 text-center my-5" v-if="channel.questions == null || channel.questions.length == 0">{{ $t("whitelistQuestions.noRes") }}</div>
      </div>

      <!-- modalQuestion -->
      <div class="modal fade" id="modalQuestion" tabindex="-1" aria-labelledby="modalQuestionLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalQuestionLabel" v-if="question != null && question.id != null">{{ $t("serverSettings.addQuestion.edit") }}</h5>
                  <h5 class="modal-title h4" id="modalQuestionLabel" v-else>{{ $t("serverSettings.addQuestion.title") }}</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
                  <div class="row m-0">
                     <div class="col-xl-6 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t("serverSettings.addQuestion.questionInfo.title") }}</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-3" v-if="question.description != null">
                                    <!-- <label class="mb-1"><i class="far fa-question color-theme font-13 mr-1"></i> Description</label>
                                    <textarea class="form-control" maxlength="200" v-model="question.description"></textarea> -->
                                    <TextAreaDeck :model="question.description" position_emoji="top" @attValor="question.description = $event;" :label='`<i class="far fa-question color-theme font-13 mr-1"></i> ${$t("serverSettings.addQuestion.questionInfo.desc")}`' />
                                 </div>
                                 <div class="col-12 px-1">
                                    <label class="mb-1"><i class="far fa-long-arrow-right color-theme font-13 mr-1"></i> {{ $t("serverSettings.addQuestion.questionInfo.answer") }}</label>
                                    <input type="text" class="form-control" maxlength="200" v-model="question.answer" v-mask="'#####'" />
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-xl-6 px-1 mb-2">
                        <div class="card mb-0">
                           <div class="card-body">
                              <div class="row m-0">
                                 <div class="col-12 px-1 mb-1">
                                    <h6 class="text-center mb-0">{{ $t("serverSettings.addQuestion.alternatives.title") }}</h6>
                                    <hr>
                                 </div>
                                 <div class="col-12 px-1 mb-3 text-center">
                                    <button type="button" class="btn btn-primary btn-sm" @click="addAlternative"><i class="far fa-plus mr-2"></i><span class="hidden-md">{{ $t("serverSettings.addQuestion.alternatives.button.add") }}</span></button>
                                 </div>

                                 <div class="col-12 px-0">
                                    <div class="row m-0">
                                       <alternative v-for="(alternative, index) in question.alternatives" :key="index" :alternative="alternative" :index="index" @delete="deleteAlternative($event)" />
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="saveQuestion">{{ $t("serverSettings.addQuestion.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("serverSettings.addQuestion.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import Swal from 'sweetalert2'
import { mapState } from 'vuex'
import TextAreaDeck from '@/components/TextAreaDeck.vue'
import question from '@/components/whitelist/Question.vue'
import alternative from '@/components/whitelist/Alternative.vue'

export default {
   name: 'Questions',
   data: function () {
		return {
         channel: {'questions': [], 'alternatives': []},
         question: {'id': null, 'alternatives': [], 'idChannel': null, 'idServer': null, 'description': null, 'answer': null},
         alternative: {'id': null, 'description': null}
      }
   },
   computed: {
		...mapState({
			urlRest: state => state.urlRest,
			serverS: state => state.serverS
		}),
	},
   components: {
      question, alternative, TextAreaDeck
   },
   methods: {
      getQuestions : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.channel.questions = [];

         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/getquestionswl',
            params: {
               idServer: this.serverS.id_s,
               idChannel: this.serverS.channelWL
            }
         }).then(response => {
            this.channel = {'questions': response.data, 'alternatives': []}

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      selectQuestion : function (question) {
         this.channel.alternatives = question.alternatives;
      },
      add : function () {
         this.question = {'id': null, 'alternatives': [], 'idChannel': String(this.serverS.channelWL), 'idServer': this.serverS.id_s, 'description': '', 'answer': null}
         $('#modalQuestion').modal('show')
      },
      edit : function (question) {
         this.question = {'id': null, 'alternatives': [], 'idChannel': null, 'idServer': null, 'description': null, 'answer': null}
         
         setTimeout(() => {
            this.question = JSON.parse(JSON.stringify(question))
            $('#modalQuestion').modal('show')
         }, 50);
      },
      addAlternative : function () {
         $('#modalQuestion').modal('hide')

         Swal.fire({
            icon: 'question',
            title: this.$t("serverSettings.swal.question.addAlternative"),
            text: this.$t("serverSettings.swal.question.descAlternative"),
            input: 'textarea',
            inputPlaceholder: 'Alternative',
            confirmButtonText: this.$t("serverSettings.swal.btnConfirm"),
            showCancelButton: true,
            cancelButtonText: this.$t("serverSettings.swal.btnCancel"),
            inputAttributes: {
               maxlength: 200
            },
            inputValidator: (value) => {
               if (String(value).trim().length == 0) {
                  return this.$t("serverSettings.swal.question.invalidAlt")
               }
            }
         }).then((result) => {
            $('#modalQuestion').modal('show')

            if (result.isConfirmed) {
               this.question.alternatives.push({'id': null, 'description': String(result.value).trim()})
            }
         })
      },
      deleteAlternative : function (index) {
         this.question.alternatives.splice(index, 1)
      },
      saveQuestion : function () {
         if (this.question.description == null || this.question.description.trim().length == 0) {
            this.$toast.fire({
               icon: 'error',
               title: this.$t("serverSettings.swal.invDesc")
            })

            return
         }

			this.$store.dispatch('alternarTelaCarregamento', true)

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/savequestion',
            headers: {
					'Content-Type': 'application/json'
            },
				data: this.question

         }).then(() => {
				$('#modalQuestion').modal('hide')
            this.getQuestions()

            this.$toast.fire({
               icon: 'success',
               title: this.$t("serverSettings.swal.question.saved")
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  })
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               })
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         })
      }
   },
   mounted() {
      this.getQuestions()
   }
}

</script>
