<template>
   <div class="card mb-1">
      <div class="body p-y-10">
         <div class="row">
            <div class="col align-self-center">
               <h6 class="limitador my-0">{{ index + 1 }}° <i class="far fa-long-arrow-right font-12 mx-2"></i> {{ alternative.description }}</h6>
            </div>
            <div class="w-max-content">
               <button type="button" class="btn btn-default btn-sm" @click="remove">
                  <span><i class="far fa-trash font-13"></i></span>
               </button>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

export default {
   name: 'Alternative',
   props: ['alternative', 'index'],
   methods: {
      remove : function () {
         this.$emit('delete', this.index)
      }
   }
}

</script>